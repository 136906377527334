/**
 * Query Mix-ins for Responsive
 *
 * @author Takuto Yanagida
 * @version 2022-01-21
 */


@mixin for-size-down($high-px, $high-mm) {
	@media screen and (max-width: #{$high-px - 0.5}), print and (max-width: #{base-to-px($high-mm) - 0.5}) {
		@content;
	}
}

@mixin for-size-only($low-px, $high-px, $low-mm, $high-mm) {
	@media screen and (min-width: $low-px) and (max-width: #{$high-px - 0.5}), print and (min-width: base-to-px($low-mm)) and (max-width: #{base-to-px($high-mm) - 0.5}) {
		@content;
	}
}

@mixin for-size-up($low-px, $low-mm) {
	@media screen and (min-width: $low-px), print and (min-width: base-to-px($low-mm)) {
		@content;
	}
}

@mixin for-ph   { @content; }
@mixin for-ph-o { @include for-size-down(600px, 148) { @content; } }
@mixin for-ta   { @include for-size-up(600px, 148) { @content; } }
@mixin for-ta-o { @include for-size-only(600px, 1200px, 148, 297) { @content; } }
@mixin for-tp   { @include for-size-up(600px, 148) { @content; } }
@mixin for-tp-o { @include for-size-only(600px, 900px, 148, 210) { @content; } }
@mixin for-tl   { @include for-size-up(900px, 210) { @content; } }
@mixin for-tl-o { @include for-size-only(900px, 1200px, 210, 297) { @content; } }
@mixin for-de   { @include for-size-up(1200px, 297) { @content; } }
@mixin for-de-o { @include for-size-only(1200px, 1800px, 297, 420) { @content; } }
@mixin for-bd   { @include for-size-up(1800px, 420) { @content; } }
@mixin for-bd-o { @include for-bd { @content; } }
